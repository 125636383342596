<i18n>
ru:
  additionalInfo: Дополнительная информация
  cancel: Отмена
  cityChange: 'Корзина будет очищена, так как меню в городах отличается.'
  cityChangeEmptyCart: 'Вы будете перенаправлены на сайт города {city}.'
  confirm: Подтвердить
  selfServiceList: Списком
  selfServiceMap: Картой
  selfServiceSelect: Выберите точку самовывоза
  subtitle: >-
    Выберите способ получения заказа и адрес — мы покажем вам актуальное меню
    ресторана
  subtitleNoAddress: >-
    Меню в наших заведениях отличается, поэтому предлагаем сразу выбрать адрес
    доставки или точку самовывоза
  title: Способ получения заказа
  yourAddress: Ваш адрес
  yourCity: Ваш город
ua:
  additionalInfo: Додаткова інформація
  cancel: Відмінивши
  cityChange: 'Кошик буде очищений, так як меню по різних містах відрізняється.'
  cityChangeEmptyCart: 'Ви будете перенаправлені на сайт міста {city}.'
  confirm: Підтверджувати
  selfServiceList: Списком
  selfServiceMap: Картою
  selfServiceSelect: Виберіть точку самовивозу
  subtitle: >-
    Виберіть спосіб отримання замовлення і адресу - ми покажемо вам актуальне
    меню ресторану
  subtitleNoAddress: >-
    Меню в наших закладах відрізняється, тому пропонуємо відразу вибрати адресу
    доставки або точку самовивозу
  title: Спосіб отримання замовлення
  yourAddress: Ваша адреса
  yourCity: Ваше місто
us:
  additionalInfo: Additional info
  cancel: Cancel
  cityChange: 'Cart will be cleared, because every city have it’s own menu'
  cityChangeEmptyCart: 'You will be redirected to {city} website.'
  confirm: Confirm
  selfServiceList: List
  selfServiceMap: Map
  selfServiceSelect: Select self-service point
  subtitle: >-
    Choose your address and delivery type — and we will show you relevant
    restaurant menu
  subtitleNoAddress: >-
    We are asking to fill in delivery address or select self-service point,
    because our menu differs between areas
  title: Select delivery type
  yourAddress: Your address
  yourCity: Your city
</i18n>

<template>
  <div
    v-if="loaded"
    class="v-address-selector-popup-container"
  >
    <div class="v-popup-head v-title v-address-select-title v-mb-sm v-font-weight-600">
      <span v-html="translate('addressSelectorPopup.title')" />
      <div
        v-if="appConfig.VueSettingsPreRun.AddressPopupShowLoginButton"
        class="v-popup-head v-title"
      >
        <account-login-button-page :hide-close-button="hideCloseButton" />
      </div>
    </div>
    <div
      v-if="restaurantData && restaurantData.length > 1"
      class="v-row v-mb-xs v-align-items-center"
    >
      <div
        id="address-selector-popup-title"
        class="v-col-3 v-font-weight-600"
        v-html="translate('addressSelectorPopup.yourCity')"
      />
      <div class="v-col-9">
        <arora-select
          required
          :selected="
            restaurantData.map((item) => item.ID).find((item) => item === appConfig.CurrentRestaurantId)
          "
          :label="translate('addressSelectorPopup.yourCity')"
          :options="restaurantData.map((item) => item.ID)"
          @change="(event: Event) => onChangeCity(event)"
          @select="(event: Event) => onChangeCity(event)"
        >
          <template #index="options: { index: number }">
            <option
              :value="restaurantData[options.index].ID"
              v-html="clean(restaurantData[options.index].Name)"
            />
          </template>
        </arora-select>
      </div>
    </div>

    <div
      class="v-address-select-element"
      v-html="
        translate(
          noAddressInitial ? 'addressSelectorPopup.subtitleNoAddress' : 'addressSelectorPopup.subtitle'
        )
      "
    />

    <cart-order-type />

    <div>
      <div
        v-if="clientStore.courierDelivery"
        class="v-address-select-element"
      >
        <lazy-account-saved-addresses-page :can-redact-address="false" />
        <maps-autocomplete :form="validatorForm" />
        <arora-summary-details
          :open="fromCart ?? false"
          :title="translate('addressSelectorPopup.additionalInfo')"
        >
          <cart-additional-info :show-title="false" />
        </arora-summary-details>
        <maps-address />
      </div>
      <div
        v-else-if="clientStore.selfService"
        class="v-address-select-element"
      >
        <div class="v-self-service-select-a-way">
          <b v-html="translate('addressSelectorPopup.selfServiceSelect')" />

          <div class="v-self-service-selector">
            <arora-option-slider
              :height="28"
              :label="translate('addressSelectorPopup.selfServiceSelect')"
              :options="[0, 1]"
              v-model:selected="selfServiceSelectorValue"
            >
              <template #option="option: { value: number }">
                <div
                  v-if="option.value === 0"
                  data-test-id="self-service-list"
                  v-html="translate('addressSelectorPopup.selfServiceList')"
                />
                <div
                  v-if="option.value === 1"
                  data-test-id="self-service-map"
                  v-html="translate('addressSelectorPopup.selfServiceMap')"
                />
              </template>
            </arora-option-slider>
          </div>
        </div>

        <lazy-cart-self-service-terminals v-if="selfServiceSelectorValue === 0" />
        <lazy-maps-terminals v-else-if="selfServiceSelectorValue === 1" />
      </div>
      <div
        v-else-if="clientStore.inHall"
        class="v-address-select-element"
      >
        <cart-in-hall-table-select />
        <maps-terminals />
      </div>
    </div>
    <cart-error-notifier />

    <transition
      v-if="appConfig.VueSettingsPreRun.AverageTimeDisplayInPopup"
      appear
      mode="out-in"
      name="fade"
    >
      <div
        v-if="clientStore.averageTimeWithDelayMs > 0"
        class="v-address-form-full"
      >
        <span
          class="v-mr-xs"
          v-html="translate('deliveryTimeRathloriel.averageTimeWarning')"
        />
        <header-parts-average-time-base is-address-selector-popup-time />
      </div>
    </transition>

    <div class="v-address-button-confirm v-text-center">
      <arora-button
        class-name="v-btn-lg btn-address-confirm"
        :disabled="noAddress || terminalIsTemporarilyNoActive"
        :label="translate('addressSelectorPopup.confirm')"
        data-test-id="btn-address-confirm"
        @click="accept"
      />
    </div>
  </div>
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import type { Restaurant } from '~types/addressStore'
import type { ExternalLink } from '~types/settingsVariants'

import { type GUID, useValidationStore } from '@arora/common'

import { getActivePinia } from 'pinia'

const {
  fromCart = false,
  onEntrance = false,
  onProductAdd = false
} = defineProps<{
  onProductAdd?: boolean | undefined
  onEntrance?: boolean | undefined
  fromCart?: boolean | undefined
}>()

const appConfig = useAppConfig()
const { clean, translate } = useI18nSanitized()

const { noAddress, redirectToRestaurant, terminalTemporarilyNoActive } = useAddressSelector()

const addressStore = useAddressStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const validatorForm = 'address-selector-popup'

onMounted(async () => {
  await Promise.all([
    addressStore.initRestaurant(),
    addressStore.initCities(),
    addressStore.initTerminals(),
    clientStore.initClientState()
  ])

  noAddressInitial.value = noAddress.value
})

const selfServiceSelectorValue = ref<number>(1)
const noAddressInitial = ref<boolean>(true)
const loaded = computed(() => addressStore.Restaurants?.data !== null)
const externalLinksAddress = ref<ExternalLink[]>(
  appConfig.VueSettingsPreRun.ExternalLinks.filter((l) => l.Type === 'address')
)

const restaurantData = computed<Restaurant[]>(() => {
  const restaurants: Restaurant[] = addressStore.Restaurants.data ?? []

  let total = [] as Restaurant[]

  if (externalLinksAddress.value.length > 0)
    for (let index = 0; index < externalLinksAddress.value.length; index++) {
      total.push({
        Active: true,
        ExternalLink: externalLinksAddress.value[index].Url,
        ID: String(index) as GUID,
        Name: externalLinksAddress.value[index].Name ?? '',
        ShowRestaurantInSelect: true,
        SortWeight: externalLinksAddress.value[index].SortWeight
      })
    }

  if (restaurants) {
    total = [
      ...total,
      ...Object.values(restaurants).filter((rest) => {
        return rest.Active && rest.ShowRestaurantInSelect
      })
    ]
  }

  return total.sort((a, b) => {
    return a.SortWeight === undefined || a.SortWeight > b.SortWeight ? 1 : -1 //sort as in the back
  })
})
const hideCloseButton = computed<boolean>(() => {
  return (
    (((appConfig.RestaurantSettingsPreRun.AddressSelectOptions?.AddressMustBeSelectedAtEntrance &&
      onEntrance) ||
      (appConfig.RestaurantSettingsPreRun.AddressSelectOptions
        ?.AddressMustBeSelectedBeforeProductAdded &&
        onProductAdd)) ??
      false) &&
    noAddressInitial.value
  )
})
const terminalIsTemporarilyNoActive = computed<boolean>(() => {
  return terminalTemporarilyNoActive.value.IsTerminalNoActive
})

function accept(): void {
  if (!validationStore.formPassedCheck(validatorForm)) {
    return
  }

  reloadNuxtApp({ force: true })
}

function onChangeCity(event: Event): void {
  const id = (event.target as HTMLInputElement).value
  if (!restaurantData.value) return
  const rest = restaurantData.value.find((rest: Restaurant) => {
    return rest.ID === id
  })
  if (!rest) return

  popupStore.closePopup().then(() => redirectToRestaurant(rest))
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-address-selector-popup-container {
  max-width: 100%;
  position: relative;
}

.v-address-select-title {
  font-size: variables.$TextSizeH3;
  align-items: baseline;
  gap: 5px;

  @include mixins.xs {
    flex-direction: column-reverse;
    margin-top: 0;
    gap: 0;
  }
}

.v-address-select-element {
  margin: 12px 0 12px;

  .v-address-select-map-container {
    height: 300px;
  }

  .v-address-select-where-to {
    margin-bottom: 10px;

    input {
      margin-bottom: 4px;
    }
  }

  .v-details-block {
    margin: 0 0 10px;

    .v-summary-content {
      .v-additional-form-address-typed {
        border: none;
        margin-bottom: 0;

        .v-additional-order-delivery-type {
          padding: 0;
        }
        .v-additional-order-delivery-type-flat {
          padding: 0;
        }
        .v-additional-order-delivery-type-org {
          padding: 0;
        }
      }
    }
  }
}

.v-address-select-self-service-info {
  margin: 15px 0 15px;
}

.v-address-select-self-service-info-gray {
  @extend .v-address-select-self-service-info;
  color: #999;
}

.suggestions-container-streets {
  position: relative;
  float: left;
  width: 400px;
}

.v-self-service-select-a-way {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  b {
    margin-bottom: 10px;
  }
}

.v-self-service-selector {
  flex: 0 0 35%;
  max-width: 35%;
  margin-bottom: 10px;
}

.v-address-button-confirm {
  margin: 1rem auto 0 auto;
}

.btn-address-confirm {
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
}

@include mixins.md {
  .v-self-service-selector {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 10px;
  }
}

@include mixins.sm {
  .v-self-service-selector {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
